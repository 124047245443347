import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Wrapper = styled.div`
  max-width: 1024px;
  margin: 0 auto;

  video {
    max-width: 1024px;
    margin: 0 auto;
    width: 100%;
    height: auto;
  }
`

const Video = ({ src, autoPlay, loop, type }) => (
  <Wrapper>
    <video autoPlay={autoPlay} loop={loop} muted playsInline>
      <track kind="captions" />
      <source src={src} type={type} />
    </video>
  </Wrapper>
)

Video.propTypes = {
  src: PropTypes.string,
  autoPlay: PropTypes.bool,
  loop: PropTypes.bool,
  type: PropTypes.string,
}

export default Video
