import Box from "../../../../src/components/box";
import PageSection from "../../../../src/components/pageSection";
import Video from "../../../../src/components/video";
import VideoSource from "../../../../content/work/id-the-population-experts/local-gov.mp4";
import * as React from 'react';
export default {
  Box,
  PageSection,
  Video,
  VideoSource,
  React
};