import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import MEDIA from 'helpers/mediaTemplates'

const Container = styled.section`
  padding: 1rem;

  ${props =>
    props.variant === 'dark' &&
    css`
      background: black;
      color: white;
    `}

    ${props =>
    props.variant === 'gray' &&
    css`
      background: #f4f4f4;
    `}
`

Container.propTypes = {
  variant: PropTypes.string,
}

const PageSection = ({ children, variant }) => (
  <Container variant={variant}>{children}</Container>
)

PageSection.propTypes = {
  variant: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default PageSection
