import Box from "../../../../src/components/box";
import PageSection from "../../../../src/components/pageSection";
import Video from "../../../../src/components/video";
import VideoSource2 from "../../../../content/work/rmit-enabling-capabilities/video.mp4";
import * as React from 'react';
export default {
  Box,
  PageSection,
  Video,
  VideoSource2,
  React
};